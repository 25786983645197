import moment from "moment";

const Today = moment();

const YesNoOptions = [{
    title: "Yes",
    code: true
}, {
    title: "No",
    code: false
}];

const StaticDataService = {
    Today,
    YesNoOptions
}

export default StaticDataService;

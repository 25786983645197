import "./SearchComponent.scss";
import InputComponent from "../form-controls/input/InputComponent";
import {useCallback, useEffect, useState} from "react";

interface SearchComponentProps {
    label?: string;
    className?: any;
    placeholder?: string;
    value?: string;
    fullWidth?: boolean;
    onChange?: (value: any) => void;
}

const SearchComponent = (props: SearchComponentProps) => {

    const {label, fullWidth, className, onChange} = props;
    const [searchText, setSearchText] = useState<string | undefined>(props.value);
    const placeholder = props.placeholder || 'Search';

    useEffect(() => {
        setSearchText(props.value);
    }, [props.value]);

    const handleSearchTextChange = useCallback((value: any) => {
        if (onChange) {
            onChange(value);
        }
        setSearchText(value);
    }, [onChange]);

    //
    // const handleSearchClear = useCallback(() => {
    //     handleSearchTextChange("");
    // }, [handleSearchTextChange]);

    return (
        <div className={'search-component'}>
            <InputComponent
                label={label}
                className={className}
                value={searchText}
                onChange={handleSearchTextChange}
                fullWidth={fullWidth}
                placeholder={placeholder}
            />
        </div>
    );

};

export default SearchComponent;

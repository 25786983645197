import {IActionModel} from "../../shared/models/action.model";
import {GET_ALL_VEHICLES_LIVE_DATA, SET_VEHICLE_LIVE_DATA} from "../actions/vehicle.action";
import moment, {Moment} from "moment";

export interface IVehicleReducerState {
    lastUpdatedAt?: Moment,
    isAllVehiclesLiveDataLoading: boolean,
    isAllVehiclesLiveDataLoaded: boolean,
    isAllVehiclesLiveDataLoadingFailed: boolean,
    allVehiclesLiveData: any
}

const initialData: IVehicleReducerState = {
    isAllVehiclesLiveDataLoading: false,
    isAllVehiclesLiveDataLoaded: false,
    isAllVehiclesLiveDataLoadingFailed: false,
    allVehiclesLiveData: [],
};

const VehicleReducer = (state = initialData, action: IActionModel): IVehicleReducerState => {
    switch (action.type) {
        case GET_ALL_VEHICLES_LIVE_DATA:
            state = {
                ...state,
                lastUpdatedAt: moment(),
                isAllVehiclesLiveDataLoading: true,
                isAllVehiclesLiveDataLoaded: false,
            };
            return state;
        case SET_VEHICLE_LIVE_DATA:
            state = {
                ...state,
                isAllVehiclesLiveDataLoading: false,
                isAllVehiclesLiveDataLoaded: !!action.payload.allVehiclesLiveData,
                isAllVehiclesLiveDataLoadingFailed: !action.payload.allVehiclesLiveData,
                allVehiclesLiveData: action.payload.allVehiclesLiveData
            };
            return state;
        default:
            return state;
    }
};

export default VehicleReducer;

interface IEnv {
    REACT_APP_API_LIVE_URL: string;
    REACT_APP_API_AUTH_URL: string;
    REACT_APP_API_MASTER_URL: string;
    REACT_APP_API_TRIPS_URL: string;
    ENV_MODE: "dev" | "test" | "uat" | "prod" | string | undefined;
    ENABLE_REDUX_LOGS: boolean;
    ENABLE_HTTP_LOGS: boolean;
}

const ENV: IEnv = {
    ENV_MODE: process.env.REACT_APP_ENV,
    REACT_APP_API_AUTH_URL: process.env.REACT_APP_API_AUTH_URL || '',
    REACT_APP_API_LIVE_URL: process.env.REACT_APP_API_LIVE_URL || '',
    REACT_APP_API_MASTER_URL: process.env.REACT_APP_API_MASTER_URL || '',
    REACT_APP_API_TRIPS_URL: process.env.REACT_APP_API_TRIPS_URL || '',
    ENABLE_REDUX_LOGS: (process.env.REACT_APP_ENABLE_REDUX_LOGS) === 'true',
    ENABLE_HTTP_LOGS: (process.env.REACT_APP_ENABLE_HTTP_LOGS) === 'true',
}

export default ENV;

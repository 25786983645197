import {ToastPlacement, ToastType} from "t-react-toast/dist/cjs/components/shared/toast.model";
import {ToastService} from "t-react-toast";

const AlertPosition: ToastPlacement = "top-right";
const AlertDuration: number = 5000;

const showToast = (message: string, type: ToastType = 'info', duration = AlertDuration) => {
    ToastService.showToast({
        type,
        message,
        duration: AlertDuration
    });
}

const AlertService = {
    showToast,
    AlertPosition
}

export default AlertService;

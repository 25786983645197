import React from 'react';
import {Link} from "react-router-dom";
import "./NotFoundScreen.scss";
import ButtonComponent from "../../shared/components/button/ButtonComponent";
import {CommonService} from "../../shared/services";

export interface NotFoundScreenProps {
}

const NotFoundScreen = (props: NotFoundScreenProps) => {
    return (
        <div className="not-found-screen screen">
            <div className="not-found-wrapper">
                <h2>Invalid Page requested</h2>
                <Link to={CommonService._routeConfig.DefaultRoute()}>
                    <ButtonComponent>
                        Go to Home
                    </ButtonComponent>
                </Link>
            </div>
        </div>

    )
};

export default NotFoundScreen;

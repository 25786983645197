import "./LoginScreen.scss";
import * as Yup from "yup";
import ButtonComponent from "../../shared/components/button/ButtonComponent";
import {useDispatch} from "react-redux";
import {useCallback, useEffect, useState} from "react";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import {CommonService} from "../../shared/services";
import FormikInputComponent from "../../shared/components/form-controls/input/formik-input/FormikInputComponent";
import {ImageConfig} from "../../constants";

interface LoginScreenProps {

}

const loginFormValidationSchema = Yup.object({
    email: Yup.string()
        .email("Email is invalid")
        .required("Email is required"),
    password: Yup.string()
        .min(8, "Password must be 8 characters")
        .max(16, "Password must be max 16 characters")
        .required("Password is required")
});


const LoginScreen = (props: LoginScreenProps) => {

    const [loginFormInitialValues, setLoginFormInitialValues] = useState<any>({
        email: "",
        password: "",
    });
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const dispatch = useDispatch();

    const onSubmit = useCallback((values: any, {setErrors}: FormikHelpers<any>) => {
        CommonService._alert.showToast("Login Successful", "success");
        // setIsLoggingIn(true);
        // CommonService._account.LoginAPICall(values)
        //     .then((response: IAPIResponseType<ILoginResponse>) => {
        //         CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
        //         dispatch(setLoggedInUserData(response.data.user));
        //         dispatch(setLoggedInUserToken(response.data.token));
        //         setIsLoggingIn(false);
        //     })
        //     .catch((error: any) => {
        //         CommonService.handleErrors(setErrors, error);
        //         setIsLoggingIn(false);
        //     })
    }, [dispatch]);

    const handleLoginWithGoogle = useCallback(() => {
        window.open('http://localhost:3008/auth/google', '_self');
        // axios.get('http://localhost:3008/auth/google')
        //     .then((response: any) => {
        //         console.log(response);
        //     }).catch((error: any) => {
        //     console.log(error);
        // });
    }, [dispatch]);

    return (
        <div className={'login-screen'}>
            <div className={'login-bg-wrapper'}>
                <div className={"login-branding-logo"}>
                    <img src={ImageConfig.LogoWhite}/>
                </div>
                <div className="login-branding-meta">
                    <div className="login-branding-welcome">
                        Welcome to Lithium!
                    </div>
                    <div className="login-branding-quote">
                        “Unleashing the Power of
                        Tomorrow's Transportation,
                        Today!”
                    </div>
                </div>
            </div>
            <div className={'login-form-wrapper'}>
                <div className={'login-form'}>
                    <div className={"login-form-title"}>Log in to your account</div>
                    <div className={"login-form-sub-title"}>Welcome back! Please enter your details.</div>
                    <div className={'login-form-social'}>
                        <button className={'login-form-social-btn'}
                                onClick={handleLoginWithGoogle}
                        >
                            <ImageConfig.GoogleIcon className={"login-form-social-btn__icon"}/>
                            <span className={"login-form-social-btn__text"}>Sign in with Google</span>
                        </button>
                    </div>
                    <div className={'login-form-separator'}>
                       <div className={'login-form-separator__text'}>OR</div>
                    </div>
                    <Formik
                        validationSchema={loginFormValidationSchema}
                        initialValues={loginFormInitialValues}
                        validateOnChange={false}
                        validateOnBlur={true}
                        enableReinitialize={true}
                        validateOnMount={true}
                        onSubmit={onSubmit}
                    >
                        {({values, validateForm}) => {
                            // eslint-disable-next-line react-hooks/rules-of-hooks
                            useEffect(() => {
                                validateForm();
                            }, [validateForm, values]);
                            return (
                                <Form className="t-form" noValidate={true}>
                                    <div className={"login-coming-soon"}>
                                        Coming Soon
                                    </div>
                                    <div className="t-form-controls">
                                        <Field name={'email'} className="t-form-control">
                                            {
                                                (field: FieldProps) => (
                                                    <FormikInputComponent
                                                        label={'Email'}
                                                        placeholder={'Enter Email'}
                                                        type={"email"}
                                                        required={true}
                                                        formikField={field}
                                                        fullWidth={true}
                                                        id={"email_input"}
                                                        disabled={true}
                                                    />
                                                )
                                            }
                                        </Field>
                                        <Field name={'password'} className="t-form-control">
                                            {
                                                (field: FieldProps) => (
                                                    <FormikInputComponent
                                                        label={'Password'}
                                                        placeholder={'Enter Password'}
                                                        required={true}
                                                        formikField={field}
                                                        fullWidth={true}
                                                        id={"password_input"}
                                                        disabled={true}
                                                    />
                                                )
                                            }
                                        </Field>
                                    </div>
                                    <div className="t-form-actions">
                                        <ButtonComponent fullWidth={true}
                                                         type={"submit"}
                                                         disabled={true}
                                        >
                                            Login
                                        </ButtonComponent>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </div>
            </div>
        </div>
    );

};

export default LoginScreen;

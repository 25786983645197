import './App.scss';
import {useEffect, useRef} from "react";
import Navigator from "./navigation/navigator";
import AlertComponent from "./shared/components/alert/AlertComponent";
import {ColorConfig} from "./constants";
import ConfirmationComponent from "./shared/components/confirmation/ConfirmationComponent";
import {createTheme, ThemeOptions, ThemeProvider} from '@mui/material/styles';
import {CommonService} from "./shared/services";
import {logout} from "./store/actions/account.action";
import {useDispatch, useSelector} from "react-redux";
import {IRootReducerState} from "./store/reducers";
import AppVersionComponent from "./shared/components/app-version/appVersionComponent";

interface AppProps {
}

export const themeOptions: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: ColorConfig.primary,
        },
        text: {
            primary: ColorConfig.textLight,
        },
        background: {
            default: ColorConfig.backgroundColor,
        },
        info: {
            main: ColorConfig.info,
            contrastText: '#FFF'
        },
        secondary: {
            main: ColorConfig.secondary,
            contrastText: '#FFF'
        },
        error: {
            main: ColorConfig.error,
            contrastText: '#FFF'
        },
        success: {
            main: ColorConfig.success,
            contrastText: ColorConfig.successContrastText
        },
        warning: {
            main: ColorConfig.warn,
            contrastText: '#FFF'
        },
    },
    typography: {fontFamily: 'Lato'},
};
const theme = createTheme(themeOptions);

const App = (props: AppProps) => {

    const dispatch = useDispatch();
    const {token} = useSelector((state: IRootReducerState) => state.account);
    const logoutSubscriptionRef = useRef(true);
    const unAuthenticationSubscriptionRef = useRef(true);

    useEffect(() => {
        CommonService._communications.logoutSubject.subscribe(() => {
            if (!logoutSubscriptionRef.current) return null;
            dispatch(logout());
        });
        return () => {
            logoutSubscriptionRef.current = false;
        }
    }, [dispatch]);

    useEffect(() => {
        CommonService._communications.UnAuthenticationSubject.subscribe(() => {
            if (!unAuthenticationSubscriptionRef.current) return null;
            dispatch(logout());
        });
        return () => {
            unAuthenticationSubscriptionRef.current = false;
        }
    }, [dispatch]);

    useEffect(() => {
        // dispatch(getAllVehiclesLiveData());
    }, [dispatch])

    return (
        // <LocalizationProvider dateAdapter={AdapterMoment}>
        // <CheckLoginComponent>
        <div className="app">
            <ThemeProvider theme={theme}>
                <Navigator/>
                <AlertComponent/>
                <ConfirmationComponent/>
                <AppVersionComponent/>
            </ThemeProvider>
        </div>
        // </CheckLoginComponent>
        // </LocalizationProvider>
    );
}

export default App;

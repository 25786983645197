import React from "react";
import TagComponent from "../components/tag/TagComponent";

interface VehicleStatusRendererProps {
    status: any;
}

export const VehicleStatusRenderer = (props: VehicleStatusRendererProps) => {

    const {status} = props;

    return (
        <div className="vehicle-status-renderer">
            <TagComponent color={'vehicle-status-chip ' + status.toLowerCase()}
                          label={status}/>
        </div>
    );
}

import "./LastUpdatedAtComponent.scss";
import React from "react";
import {Moment} from "moment";
import {CommonService} from "../../services";

interface LastUpdatedAtComponentProps {
    timestamp: Moment | undefined;
}

const LastUpdatedAtComponent = (props: LastUpdatedAtComponentProps) => {

    const {timestamp} = props;

    return (
        <div className="last-updated-at-component">
            <span className="last-updated-at-label">Last Updated At: </span> <span className="last-updated-at-value"> {timestamp ? CommonService.getSystemFormatTimeStamp(timestamp) : '-'}</span>
        </div>
    );

};

export default LastUpdatedAtComponent;

import "./BrandingComponent.scss";
import React from "react";
import {Link} from "react-router-dom";
import {CommonService} from "../../../services";
import {ImageConfig} from "../../../../constants";

interface BrandingComponentProps {

}

const BrandingComponent = (props: BrandingComponentProps) => {

    return (
        <Link to={CommonService._routeConfig.DashboardRoute()}>
            <div className={'branding-component'}>
                <img src={ImageConfig.LogoDefault} alt={'logo'}/>
            </div>
        </Link>
    );

};

export default BrandingComponent;

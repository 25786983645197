const LOCAL_STORAGE_LOGGED_IN_USER_DATA = "currentUser";
const LOCAL_STORAGE_JWT_TOKEN = "jwtToken";
const APP_NAME = "Lithium";
const API_RESPONSE_MESSAGE_KEY = "message";

export {
    APP_NAME,
    LOCAL_STORAGE_LOGGED_IN_USER_DATA,
    LOCAL_STORAGE_JWT_TOKEN,
    API_RESPONSE_MESSAGE_KEY,
}

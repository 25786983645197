import React, {useEffect, useLayoutEffect} from 'react';
import {Navigate, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import NotFoundScreen from "../screens/not-found/NotFoundScreen";
import TestScreen from "../screens/test/TestScreen";
import ComingSoonScreen from "../screens/coming-soon/ComingSoonScreen";

import {
    COMING_SOON_ROUTE,
    DASHBOARD_ROUTE,
    DESIGN_SYSTEM_ROUTE,
    HANDSHAKE_ROUTE,
    LIVE_BASE_ROUTE,
    LIVE_VEHICLE_DETAILS_ROUTE,
    LIVE_VEHICLE_LIST_ROUTE,
    LOGIN_ROUTE,
    NOT_FOUND_ROUTE,
    TEST_ROUTE,
} from "../constants/RoutesConfig";
import AppLayout from "../layouts/app-layout/AppLayout";
import {useSelector} from "react-redux";
import {IRootReducerState} from "../store/reducers";
import DashboardScreen from "../screens/dashboard/DashboardScreen";
import DesignSystemScreen from "../screens/design-system/DesignSystemScreen";
import AuthLayout from "../layouts/auth-layout/AuthLayout";
import LoginScreen from "../screens/login/LoginScreen";
import {CommonService} from "../shared/services";
import HandshakeComponent from "../shared/components/handshake/HandshakeComponent";
import LiveLayoutComponent from "../screens/live/LiveLayoutComponent";
import LiveVehicleListScreen from "../screens/live/live-vehicle-list/LiveVehicleListScreen";
import LiveVehicleDetailsScreen from "../screens/live/live-vehicle-details/LiveVehicleDetailsScreen";

const ProtectedRoute = (props: React.PropsWithChildren<any>) => {

    const {children} = props;
    const {token} = useSelector((state: IRootReducerState) => state.account);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!token) {
            navigate('/login?returnUrl=' + encodeURIComponent(location.pathname + location.search));
        }
    }, [token, navigate, location]);

    return children;
}

const UnProtectedRoute = (props: React.PropsWithChildren<any>) => {

    const {children} = props;
    const {token} = useSelector((state: IRootReducerState) => state.account);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        let returnUrl = CommonService._routeConfig.DefaultRoute();
        if (!!token) {
            const query = CommonService.parseQueryString(location.search);
            if (Object.keys(query).includes('returnUrl')) {
                returnUrl = query.returnUrl;
            }
            navigate(returnUrl);
        }
    }, [token, navigate, location])

    return children;
}

export interface NavigatorProps {

}

const Navigator = (props: NavigatorProps) => {

    const location = useLocation();

    useLayoutEffect(() => {
        document.querySelector("body")?.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <Routes>
            <Route path={'/'} element={<Navigate to={LIVE_BASE_ROUTE}/>}/>
            <Route element={<AppLayout/>}>
                <Route path={DASHBOARD_ROUTE} element={<DashboardScreen/>}/>
                <Route path={TEST_ROUTE} element={<TestScreen/>}/>
                <Route path={LIVE_BASE_ROUTE} element={<LiveLayoutComponent/>}>
                    <Route
                        index
                        element={<LiveVehicleListScreen/>}
                    />
                    <Route path={LIVE_VEHICLE_LIST_ROUTE} element={<ProtectedRoute>
                        <LiveVehicleListScreen/>
                    </ProtectedRoute>}/>
                    <Route path={LIVE_VEHICLE_DETAILS_ROUTE} element={<ProtectedRoute>
                        <LiveVehicleDetailsScreen/>
                    </ProtectedRoute>}/>
                </Route>
            </Route>
            <Route element={<AuthLayout/>}>
                <Route path={LOGIN_ROUTE} element={<LoginScreen/>}/>
            </Route>
            <Route path={HANDSHAKE_ROUTE} element={<HandshakeComponent/>}/>
            <Route path={COMING_SOON_ROUTE} element={<ComingSoonScreen/>}/>
            <Route path={DESIGN_SYSTEM_ROUTE} element={<DesignSystemScreen/>}/>
            <Route path={NOT_FOUND_ROUTE} element={<NotFoundScreen/>}/>
            <Route path="*" element={<Navigate to={NOT_FOUND_ROUTE}/>}/>
        </Routes>
    )
};

export default Navigator;

import './TestScreen.scss';

interface TestScreenProps {

}

const TestScreen = (props: TestScreenProps) => {

    return (
        <div className={'test-screen'}>
            Test Screen
        </div>
    );

};
export default TestScreen;

// AUTH ROUTES
const LOGIN_ROUTE = "/login";
const HANDSHAKE_ROUTE = "/handshake";

// APP ROUTES
const DASHBOARD_ROUTE = "/dashboard";
const COMING_SOON_ROUTE = "/coming-soon";

const LIVE_BASE_ROUTE = "/live";
const LIVE_VEHICLE_LIST_ROUTE = "/live/vehicle-list";
const LIVE_VEHICLE_DETAILS_ROUTE = "/live/vehicle-details/:vehicleRegNumber";

// GUEST ROUTES
const TEST_ROUTE = "/test";
const NOT_FOUND_ROUTE = "/not-found";
const DESIGN_SYSTEM_ROUTE = "/ds";

const GUEST_ROUTES = [NOT_FOUND_ROUTE, DESIGN_SYSTEM_ROUTE];

export {
    COMING_SOON_ROUTE,
    NOT_FOUND_ROUTE,
    GUEST_ROUTES,
    TEST_ROUTE,
    DESIGN_SYSTEM_ROUTE,
    LOGIN_ROUTE,
    DASHBOARD_ROUTE,
    LIVE_BASE_ROUTE,
    LIVE_VEHICLE_LIST_ROUTE,
    HANDSHAKE_ROUTE,
    LIVE_VEHICLE_DETAILS_ROUTE
};

import React from "react";
import {Outlet} from "react-router-dom";
import HeaderComponent from "../../shared/components/layout/header/HeaderComponent";

export interface AppLayoutProps {

}

const AppLayout = (props: AppLayoutProps) => {

    return (
        <div className="app-layout">
            <div className="header-container">
                <HeaderComponent/>
            </div>
            <div className="content-container">
                <Outlet/>
            </div>
        </div>
    );
}


export default AppLayout;





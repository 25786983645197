import {useState} from 'react';
import {CommonService} from '../services';
import moment, {Moment} from "moment";

type HttpMethod = 'get' | 'post' | 'put' | 'delete';

interface IFetchData {
    url: string;
    method?: HttpMethod;
    payload?: any;
    headers?: HeadersInit;
}

interface FetchResult<T> {
    response: T | null;
    error: any | null;
    loading: boolean;
    loaded: boolean;
    loadingFailed: boolean;
    requestedAt?: Moment;
    fetchData: (config: IFetchData) => void;
}

const useFetch = <T>(): FetchResult<T> => {

    const [response, setResponse] = useState<T | null>(null);
    const [ requestedAt, setRequestedAt ] = useState<Moment>();
    const [error, setError] = useState<Error | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [loadingFailed, setLoadingFailed] = useState<boolean>(false);

    const fetchData = (config: IFetchData) => {
        const {url, method, payload, headers} = config;
        if (!url) return;
        setLoading(true);
        let apiCall: Promise<any> | undefined;
        switch (method) {
            case 'get':
                apiCall = CommonService._api.get(url, payload, headers);
                break;
            case 'post':
                apiCall = CommonService._api.post(url, payload, headers);
                break;
            case 'put':
                apiCall = CommonService._api.put(url, payload, headers);
                break;
            case 'delete':
                apiCall = CommonService._api.delete(url, payload, headers);
                break;
            default:
                apiCall = CommonService._api.get(url, payload, headers);
                break;
        }
        setRequestedAt(moment());
        apiCall
            .then((res: any) => {
                setResponse(res?.data || res);
                setLoaded(true);
                setLoadingFailed(false);
            })
            .catch((err) => {
                setError(err);
                setLoaded(false);
                setLoadingFailed(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return {response, error, loading, loaded, loadingFailed, requestedAt, fetchData};
};

export default useFetch;

import "./TableComponent.scss";
import React, {useCallback, useEffect, useMemo} from "react";
import {ITableColumn, ITableComponentProps} from "../../models/table.model";
import LoaderComponent from "../loader/LoaderComponent";
import StatusCardComponent from "../status-card/StatusCardComponent";
import DataGrid from 'react-data-grid';
import 'react-data-grid/lib/styles.css';

interface TableComponentProps extends ITableComponentProps {
    loading?: boolean;
    errored?: boolean;
    id?: string;
    columns: ITableColumn[];
    data: any[];
}

const TableComponent = (props: TableComponentProps) => {

    const {
        loading,
        errored,
        hideHeader,
        columns,
        onRowClick,
        onCellClick,
        data,
        // sort,
        onSort
    } = props;
    const columnGap = props.columnGap || 20;
    const size = props.size || "normal";

    const parseRender = useCallback((col: ITableColumn, data: any, index: number) => {
        if (col.render) {
            return col.render(data, index);
        }
    }, []);

    const TransformColumn = useCallback((column: ITableColumn, isLastColumn?: boolean) => {
        const colObject: any = {
            name: column?.title || "  ",
            key: column?.key,
            align: column?.align || "left",
            frozen: column?.fixed,
            sortable: column?.sortable,
            cellClass: column?.render ? "custom-cell" : null,
        };
        if (column?.render) {
            colObject['formatter'] = ({row}: any) => {
                return parseRender(column, row, row?.rowIndex);
            };
        }
        if (column?.width && !isLastColumn) {
            colObject['width'] = column?.width;
        }
        if (column?.children) {
            const childrenLength = column?.children?.length;
            colObject['columns'] = column.children.map((child: ITableColumn, index) => TransformColumn(child, index === childrenLength - 1));
        }
        return colObject;
    }, [parseRender]);

    const parseColumns = useCallback((columns: ITableColumn[]) => {
        const transformedCols: any = columns.map((column: ITableColumn, index) => {
            return TransformColumn(column, index === columns.length - 1);
        });
        return transformedCols;
    }, [TransformColumn]);

    const columnsMemoized = useMemo<any>(() =>
            parseColumns(columns)
        , [columns, parseColumns]);

    const dataMemoized = useMemo<any>(() =>
            data || []
        , [data]);

    // const getTHClasses = useCallback((column: ITableColumn) => {
    //     let classes = 'th t-th t-cell t-cell-' + column.key?.split(' ').join('-') + " " + column.className + ' t-cell-align-' + column.align + ' pdd-right-' + columnGap;
    //     if (column?.sortable) {
    //         classes += " sortable";
    //         if (sort && sort.key === column.key) {
    //             if (sort.order === "asc") {
    //                 classes += " sort-asc";
    //             } else {
    //                 classes += " sort-desc";
    //             }
    //         }
    //     }
    //     return classes;
    // }, [sort, columnGap]);
    //
    // const getTDClasses = useCallback((column: ITableColumn) => {
    //     return 'td t-td t-cell t-cell-' + column.key?.split(' ').join('-') + " " + column.className + ' t-cell-align-' + column.align + ' pdd-right-' + columnGap;
    // }, [columnGap]);

    const handleRowClick = useCallback((data: any) => {
        if (onRowClick) {
            const cellRowData = data.row;
            onRowClick(cellRowData);
        }
    }, [onRowClick]);

    const handleCellClick = useCallback((data: any) => {
        const cellRowData = data.row;
        const cellColData = data.row;
        if (onCellClick) {
            onCellClick(cellRowData, cellColData);
        }
        handleRowClick(data);
    }, [onCellClick, handleRowClick]);

    // const applySort = useCallback((column: ITableColumn) => {
    //     if (!column.sortable || !sort) return;
    //     const sortObj: any = _.cloneDeep(sort);
    //     if (sortObj.key === column.key) {
    //         sortObj.key = column.key;
    //         if (sortObj.order === "asc") {
    //             sortObj.order = "desc";
    //         } else {
    //             sortObj.order = "";
    //             sortObj.key = "";
    //         }
    //     } else {
    //         sortObj.key = column.key;
    //         sortObj.order = "asc";
    //     }
    //     if (onSort) {
    //         onSort(sortObj.key, sortObj.order);
    //     }
    // }, [sort, onSort]);

    useEffect(() => {
        // suppress ResizeObserver error
        window.addEventListener('error', e => {
            if (e.message === 'ResizeObserver loop limit exceeded') {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                );
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        });
    }, []);

    return (
        <div className={'table-component t-table-wrapper'}>
            <DataGrid
                className={`rdg-light t-table`}
                columns={columnsMemoized}
                rows={dataMemoized}
                onCellClick={handleCellClick}
                headerRowHeight={hideHeader ? 0 : 48}
                rowHeight={48}
            />
            {
                loading && <div className="t-table__loader">
                    <LoaderComponent size={"md"}/>
                </div>
            }
            {
                (!loading && errored) && <div className="t-table__error-card">
                    <StatusCardComponent title={"Error loading data"}/>
                </div>
            }
            {
                (!loading && !errored && data?.length === 0) && <div className="t-table__no-data-card">
                    <StatusCardComponent title={"No data found"}/>
                </div>
            }
        </div>
    );
};

export default TableComponent;

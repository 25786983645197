export const GET_ALL_VEHICLES_LIVE_DATA = "GET_ALL_VEHICLES_LIVE_DATA";
export const SET_VEHICLE_LIVE_DATA = "SET_VEHICLE_LIVE_DATA";

export const getAllVehiclesLiveData = () => {
    return {
        type: GET_ALL_VEHICLES_LIVE_DATA,
    }
};

export const setAllVehiclesLiveData = (allVehiclesLiveData: any) => {
    return {
        type: SET_VEHICLE_LIVE_DATA,
        payload: {
            allVehiclesLiveData
        }
    }
};

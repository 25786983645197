import React from "react";
import {Outlet} from "react-router-dom";
import BrandingComponent from "../../shared/components/layout/branding/BrandingComponent";

export interface AuthLayoutProps {

}

const AuthLayout = (props: React.PropsWithChildren<AuthLayoutProps>) => {

    return (
        <div className="auth-layout">
            <div className={'auth-wrapper'}>
                <Outlet/>
            </div>
        </div>
    )
}


export default AuthLayout;

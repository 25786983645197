import ENV from "./ENV";

interface IAPIConfig {
    [k: string]: {
        URL: string | Function | any,
        METHOD: "get" | "post" | "delete" | "put"
    }
}

const APIConfig: IAPIConfig = {
    MAP_LIVE_SOCKET: {
        URL: ENV.REACT_APP_API_LIVE_URL + '/',
        METHOD: 'get'
    },
    GET_ALL_VEHICLES_LIVE_STATUS: {
        URL: ENV.REACT_APP_API_LIVE_URL + '/debug?readable=true',
        METHOD: 'get'
    },
    GET_ALL_VEHICLES_MAP_LIVE_STATUS: {
        URL: ENV.REACT_APP_API_LIVE_URL + '/map/live',
        METHOD: 'get'
    },
    GET_VEHICLE_LIVE_DETAILS: {
        URL: (vehicleName: string) => ENV.REACT_APP_API_LIVE_URL + '/device/live/' + vehicleName,
        METHOD: 'get'
    },
    GET_VEHICLE_LIVE_CHART_DATA: {
        URL: (vehicleName: string) => ENV.REACT_APP_API_LIVE_URL + '/device/chart/' + vehicleName,
        METHOD: 'get'
    },
    GET_VEHICLE_24H_TIMELINE_DETAILS: {
        URL: "https://dayvehicleutilisationreport-v7zjtayvwq-el.a.run.app/",
        METHOD: 'get'
    },
    GET_VEHICLE_24H_TIMELINE_MINUTE_BLOCK_DETAILS: {
        URL: "https://dayvehicleutilisationreport-v7zjtayvwq-el.a.run.app/",
        METHOD: 'get'
    },
    GET_USERS: {
        URL: ENV.REACT_APP_API_MASTER_URL + '/user',
        METHOD: 'get'
    },
    GET_VEHICLES: {
        URL: ENV.REACT_APP_API_MASTER_URL + '/vehicle',
        METHOD: 'get'
    },
    GET_SERVICE_PROVIDERS: {
        URL: ENV.REACT_APP_API_MASTER_URL + '/service-provider',
        METHOD: 'get'
    },
    GET_CLIENTS: {
        URL: ENV.REACT_APP_API_MASTER_URL + '/client',
        METHOD: 'get'
    },
    GET_WORKSHOPS: {
        URL: ENV.REACT_APP_API_MASTER_URL + '/workshop',
        METHOD: 'get'
    },
    GET_SITES: {
        URL: ENV.REACT_APP_API_MASTER_URL + '/site',
        METHOD: 'get'
    },
    GET_CLUSTERS: {
        URL: ENV.REACT_APP_API_MASTER_URL + '/cluster',
        METHOD: 'get'
    },
    GET_HUBS: {
        URL: ENV.REACT_APP_API_MASTER_URL + '/hub',
        METHOD: 'get'
    },
    GET_CITIES: {
        URL: ENV.REACT_APP_API_MASTER_URL + '/city',
        METHOD: 'get'
    },
    GET_LOCATIONS: {
        URL: ENV.REACT_APP_API_MASTER_URL + '/location',
        METHOD: 'get'
    },
    GET_RECONCILED_TRIPS: {
        URL: ENV.REACT_APP_API_TRIPS_URL + '/trips/reconciled',
        METHOD: 'get'
    },
    GET_UNRECONCILED_TRIPS: {
        URL: ENV.REACT_APP_API_TRIPS_URL + '/trips/unreconciled',
        METHOD: 'get'
    },
}

export default APIConfig;

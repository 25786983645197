import { Subject } from "rxjs";
import {IConfirmationConfig} from "../models/confirmation.model";
import {IAttachment, ITableWrapperRefreshSubject} from "../models/common.model";

const logoutSubject: Subject<void> = new Subject();
const UnAuthenticationSubject: Subject<void> = new Subject();
const updateLoginUserTokenSubject: Subject<string | undefined> = new Subject<string | undefined>()
const PageLoadingStateSubject: Subject<boolean> = new Subject<boolean>();
const LightBoxSubject: Subject<IAttachment[]> = new Subject<IAttachment[]>();
const TableWrapperRefreshSubject: Subject<ITableWrapperRefreshSubject> = new Subject<ITableWrapperRefreshSubject>();
const FetchPageDataSubject: Subject<any> = new Subject<any>();
const DialogStateSubject: Subject<{ component: any, promise: { resolve: any, reject: any } }> = new Subject<{ component: any; promise: { resolve: any; reject: any } }>()
const ConfirmStateSubject: Subject<{ config: IConfirmationConfig, promise: { resolve: any, reject: any } }> = new Subject<{ config: IConfirmationConfig; promise: { resolve: any; reject: any } }>()

const Communications = {
    logoutSubject,
    updateLoginUserTokenSubject,
    UnAuthenticationSubject,
    PageLoadingStateSubject,
    ConfirmStateSubject,
    DialogStateSubject,
    FetchPageDataSubject,
    LightBoxSubject,
    TableWrapperRefreshSubject
};

export default Communications;

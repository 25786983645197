import React from "react";
import "./CardComponent.scss";

interface CardComponentProps {
    title?: string;
    options?: React.ReactNode;
    className?: string;
    shadow?: boolean;
    actions?: React.ReactNode;
}

const CardComponent = (props: React.PropsWithChildren<CardComponentProps>) => {

    const {title, options, actions, className, shadow, children} = props;

    return (
        <div className={`card-component ${className} ${shadow ? 'shadow' : ''}`}>
            {(title || options || actions) && <div className="card-header">
                <div className="card-title-description-wrapper">
                    <div className="card-title">
                        {title}
                    </div>
                </div>
                {
                    options && <div className="card-options">
                        {options}
                    </div>
                }
                {
                    actions && <div className="card-actions">
                        {actions}
                    </div>
                }
            </div>}
            {
                (React.Children.count(children)) > 0 && <div className="card-body">
                    {children}
                </div>
            }
        </div>
    );

};

export default CardComponent;

import React from 'react';
import {CommonService} from "../../services";
import { ToastComponent } from "t-react-toast";

interface AlertComponentProps {

}

const AlertComponent = (props: AlertComponentProps) => {

    return (
        <ToastComponent position={CommonService._alert.AlertPosition}/>
    )
};

export default AlertComponent;

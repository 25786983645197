import "./TableWrapperComponent.scss";

import * as React from 'react';
import {useCallback, useEffect, useRef, useState} from 'react';
import {IAPIResponseType} from "../../models/api.model";
import {ITableComponentProps} from "../../models/table.model";
import {CommonService} from "../../services";
import PaginationComponent from "../pagination/PaginationComponent";
import _ from "lodash";
import TableComponent from "../table/TableComponent";
import {AXIOS_REQUEST_CANCELLED} from "../../services/api.service";

export interface TableComponentProps extends ITableComponentProps {
    url: string,
    method: "get" | "post" | string,
    isPaginated?: boolean,
    moduleName?: string,
    extraPayload?: any;
    onTableDataChange?: (event: "load", data: any[]) => void;
}

const TableWrapperComponent = (props: TableComponentProps) => {

    const {id, url, method, moduleName, onTableDataChange, extraPayload, ...otherProps} = props;

    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
    const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
    const [isDataLoadingFailed, setIsDataLoadingFailed] = useState<boolean>(false);
    const [data, setData] = useState<any>([]);
    const pageNumRef = useRef<number>(0);
    const totalResultsRef = useRef<number>(0);
    const pageSizeRef = useRef<number>(10);
    const fetchPageDataSubscriptionRef = useRef<boolean>(true);
    const isPaginated = props.isPaginated !== undefined ? props.isPaginated : true;
    const APICallSubscription = useRef<any>(null);

    const getListData = useCallback(() => {
        const cancelTokenSource = CommonService.getCancelToken();
        const payload = _.cloneDeep({page: pageNumRef.current + 1, limit: pageSizeRef.current, ...extraPayload});
        if (payload?.sort && payload?.sort?.key) { // TODO to make sort more consistent
            delete payload.sort.key;
            delete payload.sort.order;
            payload.sort[payload.sort.key] = payload?.sort?.order;
        } else {
            delete payload.sort;
        }
        let apiCall;
        if (method === "post") {
            apiCall = CommonService._api.post;
        } else {
            apiCall = CommonService._api.get;
        }
        if (APICallSubscription && APICallSubscription.current) {
            APICallSubscription.current.cancel();
        }
        APICallSubscription.current = cancelTokenSource;
        setIsDataLoading(true);
        setIsDataLoaded(false);
        setIsDataLoadingFailed(false);
        let listData: any[] = [];
        apiCall(url, payload, {}, {cancelToken: cancelTokenSource.token}).then((response: IAPIResponseType<any>) => {
            if (response.data) {
                if (isPaginated) {
                    listData = response?.data?.data?.docs || [];
                    totalResultsRef.current = response?.data?.data?.total;
                } else {
                    listData = response?.data?.data;
                }
            }
            setData(listData.map((row, rowIndex) => ({...row, rowIndex})));
            setIsDataLoading(false);
            setIsDataLoaded(true);
            setIsDataLoadingFailed(false);
        }).catch((error) => {
            console.log(error);
            if (error.reason !== AXIOS_REQUEST_CANCELLED) { // if previous request got cancelled do not close loading state
                setData(listData);
                setIsDataLoading(false);
                setIsDataLoaded(false);
                setIsDataLoadingFailed(true);
            }
        }).finally(() => {
            onTableDataChange && onTableDataChange("load", listData);
        });
    }, [isPaginated, method, url, extraPayload, onTableDataChange]);

    useEffect(() => {
        const sub = CommonService._communications.TableWrapperRefreshSubject.subscribe((data) => {
            if (data.moduleName === moduleName) {
                getListData();
            }
        });
        return () => {
            sub.unsubscribe();
        }
    }, [getListData, moduleName]);

    useEffect(() => {
        getListData();
    }, [getListData]);

    const handlePageNumberChange = useCallback((event: unknown, newPage: number) => {
        pageNumRef.current = newPage;
        getListData();
    }, [getListData]);

    const handlePageSizeChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        pageSizeRef.current = +event.target.value;
        pageNumRef.current = 0;
        getListData();
    }, [getListData]);

    useEffect(() => {
        CommonService._communications.FetchPageDataSubject.subscribe(() => {
            if (!fetchPageDataSubscriptionRef.current) return null;
            getListData();
        });
        return () => {
            fetchPageDataSubscriptionRef.current = false;
        }
    }, [getListData]);

    return (
        <>
            {
                <TableComponent
                    loading={isDataLoading}
                    errored={isDataLoadingFailed}
                    data={data}
                    id={id}
                    // sort={extraPayload?.sort}
                    {...otherProps}
                />
            }
            {
                (isDataLoaded && (data && data?.length) > 0 && isPaginated) && <PaginationComponent
                    paginationOptions={[10, 25, 100]}
                    totalResults={totalResultsRef.current}
                    limit={pageSizeRef.current}
                    page={pageNumRef.current}
                    onPageChange={handlePageNumberChange}
                    onRowsPerPageChange={handlePageSizeChange}
                    id={id + "_pagination"}
                />
            }
        </>
    );
};

export default TableWrapperComponent;

// ****************************** USAGE ****************************** //

// <TableWrapperComponent
//     columns={[
//         {
//             key: "name",
//             dataIndex: "name",
//             title: "Name"
//         },
//         {
//             key: "description",
//             dataIndex: "description",
//             title: "Description"
//         }
//     ]}
//     rowKey={(record: any) => {
//         return record._id;
//     }}
//     url={ENV.API_URL + "/category/637caaa4ef59e8a8cdc9f4b3/service"}
//     method={"get"}
//     isPaginated={true}
// />

// ****************************** USAGE ****************************** //

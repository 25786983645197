import {
    GET_LANGUAGE_LIST,
    SET_LANGUAGE_LIST,
} from "../actions/static-data.action";
import {IActionModel} from "../../shared/models/action.model";

export interface IStaticDataReducerState {
    isLanguageListLoading: boolean,
    isLanguageListLoaded: boolean,
    languageList: any[]
}

const initialData: IStaticDataReducerState = {
    isLanguageListLoading: false,
    isLanguageListLoaded: false,
    languageList: [],
};

const StaticDataReducer = (state = initialData, action: IActionModel): IStaticDataReducerState => {
    switch (action.type) {
        case GET_LANGUAGE_LIST:
            state = {
                ...state,
                isLanguageListLoading: true,
                isLanguageListLoaded: false,
            };
            return state;
        case SET_LANGUAGE_LIST:
            state = {
                ...state,
                isLanguageListLoading: false,
                isLanguageListLoaded: true,
                languageList: action.payload.languageList
            };
            return state;
        default:
            return state;
    }
};

export default StaticDataReducer;

import {call, put, takeEvery} from "redux-saga/effects";

import {
    GET_LANGUAGE_LIST,
    setLanguageList,
} from "../actions/static-data.action";
import {CommonService} from "../../shared/services";

function* getLanguageList() {
    try {
        // @ts-ignore
        const resp = yield call(CommonService._staticData.getLanguageList);
        yield put(setLanguageList(resp?.data));
    } catch (error: any) {
        yield put(setLanguageList([]));
    }
}

export default function* staticDataSaga() {
    yield takeEvery(GET_LANGUAGE_LIST, getLanguageList);
}

import "./LiveLayoutComponent.scss";
import {Outlet} from "react-router-dom";

interface LiveScreenProps {

}

const LiveLayoutComponent = (props: LiveScreenProps) => {
    return <Outlet/>
};

export default LiveLayoutComponent;

import {ILanguage} from "../../shared/models/common.model";

export const GET_LANGUAGE_LIST = 'GET_LANGUAGE_LIST';
export const SET_LANGUAGE_LIST = 'SET_LANGUAGE_LIST';

export const getLanguageList = () => {
    return {type: GET_LANGUAGE_LIST};
};

export const setLanguageList = (languageList: ILanguage[]) => {
    return {
        type: SET_LANGUAGE_LIST, payload: {
            languageList
        }
    };
};


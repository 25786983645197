// import {ApiService} from "../index";
// import {APIConfig} from "../../../constants";
//
// const GetAllVehiclesLiveStatusAPICall = () => {
//     return ApiService[APIConfig.GET_ALL_VEHICLES_LIVE_STATUS.METHOD](APIConfig.GET_ALL_VEHICLES_LIVE_STATUS.URL);
// }
//
// const GetAllVehiclesMapLiveStatusAPICall = (payload: any) => {
//     return ApiService[APIConfig.GET_ALL_VEHICLES_MAP_LIVE_STATUS.METHOD](APIConfig.GET_ALL_VEHICLES_MAP_LIVE_STATUS.URL, payload);
// }
//
// const GetVehicleLiveDetailsAPICall = (vehicleName: string) => {
//     return ApiService[APIConfig.GET_VEHICLE_LIVE_DETAILS.METHOD](APIConfig.GET_VEHICLE_LIVE_DETAILS.URL(vehicleName));
// }
//
// const GetVehicleLiveChartDetailsAPICall = (vehicleName: string, payload: any) => {
//     return ApiService[APIConfig.GET_VEHICLE_LIVE_CHART_DATA.METHOD](APIConfig.GET_VEHICLE_LIVE_CHART_DATA.URL(vehicleName), payload);
// }
//
// const GetVehicle24HTimelineDetailsAPICall = (vehicleName: string, date: string) => {
//     return ApiService[APIConfig.GET_VEHICLE_24H_TIMELINE_DETAILS.METHOD](APIConfig.GET_VEHICLE_24H_TIMELINE_DETAILS.URL, {
//         date,
//         vehicle: vehicleName,
//         transform: true,
//         trips: true
//     });
// }
//
// const GetVehicle24HTimelineMinuteBlockDetailsAPICall = (vehicleName: string, date: string) => {
//     return ApiService[APIConfig.GET_VEHICLE_24H_TIMELINE_MINUTE_BLOCK_DETAILS.METHOD](APIConfig.GET_VEHICLE_24H_TIMELINE_MINUTE_BLOCK_DETAILS.URL, {
//         date,
//         vehicle: vehicleName,
//         transform: 1,
//     });
// }

const VehicleService = {
    // GetAllVehiclesLiveStatusAPICall,
    // GetAllVehiclesMapLiveStatusAPICall,
    // GetVehicleLiveDetailsAPICall,
    // GetVehicleLiveChartDetailsAPICall,
    // GetVehicle24HTimelineDetailsAPICall,
    // GetVehicle24HTimelineMinuteBlockDetailsAPICall
}


export default VehicleService;

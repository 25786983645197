import "./TagComponent.scss";

interface TagComponentProps {
    label: string;
    color?: 'success' | 'danger' | 'warning' | 'default' | 'info' | 'primary' | string;
    showIndicator?: boolean;
}

const TagComponent = (props: TagComponentProps) => {

    const {label, showIndicator} = props;
    const color = props.color || 'primary';

    return (
        <div className={`tag-component ${color}`}>
            {showIndicator && <div className={`indicator ${color}`}/>}
            <div className={`label ${color}`}>{label}</div>
        </div>
    );

};

export default TagComponent;

// #####************** START USAGE **************#####
// import TagComponent from "../../shared/components/tag/TagComponent";
// <TagComponent label={'Checked In'} showIndicator={true} color={'success'}/>
// #####************** END USAGE **************#####

import "./MenuComponent.scss";
import {NavLink} from "react-router-dom";
import {IMenuItem} from "../../../models/menu.model";
import {CommonService} from "../../../services";
import {Menu_AccidentForensicsIcon, Menu_DashboardIcon, Menu_LiveIcon} from "../../../../constants/ImageConfig";
import {ImageConfig} from "../../../../constants";
import {useCallback, useState} from "react";

// import {DashboardIcon, MapIcon, MasterIcon, TripsIcon} from "../../../../constants/ImageConfig";

interface MenuComponentProps {

}

const menuList: IMenuItem[] = [
    {
        path: "",
        title: 'Dashboard',
        icon: <Menu_DashboardIcon/>
    },
    {
        path: CommonService._routeConfig.LiveBaseRoute(),
        title: 'Live',
        icon: <Menu_LiveIcon/>,
        subMenu: [
            {
                path: CommonService._routeConfig.LiveVehicleListRoute(),
                title: 'List View',
            },
            {
                path: "",
                title: 'Map View',
            }
        ]
    },
    {
        path: "",
        title: 'Accident Forensics',
        icon: <Menu_AccidentForensicsIcon/>
    }
]

const MenuComponent = (props: MenuComponentProps) => {

        const [hoveredMenuItem, setHoveredMenuItem] = useState<string>("");
        const [showSubMenu, setShowSubMenu] = useState<boolean>(false);

        const showSubMenuList = useCallback((menu: IMenuItem) => {
            setHoveredMenuItem(menu.title);
            setShowSubMenu(true);
        }, []);

        const hideSubMenuList = useCallback(() => {
            setHoveredMenuItem("");
            setShowSubMenu(false);
        }, []);

        return (
            <div className={'menu-component'}>
                <div className='menu-list'>
                    {
                        menuList.map((menu, index) => {
                            return <div className={'menu-item-wrapper'}
                                        onMouseOver={() => {
                                            showSubMenuList(menu);
                                        }}
                                        onMouseLeave={() => {
                                            hideSubMenuList();
                                        }}
                            >
                                <NavLink key={menu.title}
                                         onClick={(e) => {
                                             hideSubMenuList();
                                             if (!menu.path) {
                                                 CommonService.ComingSoon();
                                                 e.preventDefault();
                                             }
                                         }}
                                         to={menu.path} className="menu-item"
                                         id={`${menu.title.toLowerCase().split(' ').join('_')}_menu`}
                                         {...props}
                                >
                                    {menu.icon && <span className="menu-item-icon">{menu.icon}</span>}<span
                                    className="menu-item-title">{menu.title}</span>
                                    {
                                        menu?.subMenu && <span className="sub-menu-item-icon">
                                            <ImageConfig.Menu_DownArrowIcon/>
                                    </span>
                                    }
                                </NavLink>
                                {
                                    (menu?.subMenu && showSubMenu && hoveredMenuItem === menu.title) &&
                                    <div className="sub-menu-list">
                                        {
                                            menu?.subMenu?.map((subMenu, index) => {
                                                return <>
                                                    <NavLink key={subMenu.title}
                                                             onClick={(e) => {
                                                                 hideSubMenuList();
                                                                 if (!subMenu.path) {
                                                                     CommonService.ComingSoon();
                                                                     e.preventDefault();
                                                                 }
                                                             }}
                                                             to={subMenu.path} className="sub-menu-item"
                                                             id={`${subMenu.title.toLowerCase().split(' ').join('_')}_sub_menu`}
                                                             {...props}
                                                    >
                                                        <span
                                                            className="sub-menu-item-title">{subMenu.title}</span>
                                                    </NavLink>
                                                </>
                                            })
                                        }
                                    </div>
                                }
                            </div>

                        })
                    }
                </div>
            </div>
        );

    }
;

export default MenuComponent;

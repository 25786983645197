import "./DashboardStatWidgetComponent.scss";
import {Moment} from "moment";

interface DashboardStatWidgetComponentProps {
    title: string;
    value: string | number;
    units?: string;
    timestamp?: Moment;
}

const DashboardStatWidgetComponent = (props: DashboardStatWidgetComponentProps) => {

    const {title, value, units, timestamp} = props;

    return (
        <div className={'dashboard-stat-widget-component dashboard-stat-widget'}>
            <div className={'widget__title'}>
                {title}
            </div>
            <div className={'widget__value-wrapper'}>
                <div className="widget__value">
                    {value}
                </div>
                {
                    units && <div className="widget__units">
                        {units}
                    </div>
                }
            </div>
            <div className={'widget__timestamp'}>
                {timestamp?.format('h:mm A | DD-MM-YYYY')}
            </div>
        </div>
    );

};

export default DashboardStatWidgetComponent;

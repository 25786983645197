import "./HeaderComponent.scss";
import BrandingComponent from "../branding/BrandingComponent";
import MenuComponent from "../menu/MenuComponent";
import {ImageConfig} from "../../../../constants";

interface HeaderComponentProps {

}

const HeaderComponent = (props: HeaderComponentProps) => {

    return (
        <div className="app-header">
            <div className="app-header__branding_menu-wrapper">
                <div className="app-header__branding-wrapper">
                    <BrandingComponent/>
                </div>
                <div className="app-header__menu-wrapper">
                    <MenuComponent/>
                </div>
            </div>
            <div className="app-header__utils_and_profile-wrapper">
                <div className="app-header__utils-wrapper">
                    <ImageConfig.Header_DirectionsIcon className="app-header__util"/>
                </div>
                <div className="app-header__profile-wrapper">
                    <div className="app-header__profile-avatar">
                        DN
                    </div>
                    <div className="app-header__profile-name">
                        Dinesh Nayyar
                    </div>
                    <div className="app-header__profile-arrow">
                        <ImageConfig.Header_DownArrowIcon/>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default HeaderComponent;

import "./LiveVehicleDetailsScreen.scss";
import {useCallback, useEffect} from "react";
import {useParams} from "react-router-dom";
import {APIConfig, ImageConfig} from "../../../constants";
import LinkComponent from "../../../shared/components/link/LinkComponent";
import TagComponent from "../../../shared/components/tag/TagComponent";
import useFetch from "../../../shared/hooks/useFetch";
import LastUpdatedAtComponent from "../../../shared/components/last-updated-at/LastUpdatedAtComponent";
import {CommonService} from "../../../shared/services";
import LoaderComponent from "../../../shared/components/loader/LoaderComponent";
import CardComponent from "../../../shared/components/card/CardComponent";
import DataLabelValueComponent from "../../../shared/components/data-label-value/DataLabelValueComponent";
import DashboardStatWidgetComponent
    from "../../../shared/components/dashboard-stat-widget/DashboardStatWidgetComponent";
import StatusCardComponent from "../../../shared/components/status-card/StatusCardComponent";
import moment from "moment";

interface LiveVehicleDetailsScreenProps {

}

const LiveVehicleDetailsScreen = (props: LiveVehicleDetailsScreenProps) => {

    const {vehicleRegNumber} = useParams();

    const {
        response: vehicleDataResponse,
        error: vehicleDataError,
        loading: isVehicleDataLoading,
        loaded: isVehicleDataLoaded,
        loadingFailed: isVehicleDataLoadingFailed,
        requestedAt: vehicleDataRequestedAt,
        fetchData: getVehicleDetails
    } = useFetch<any>();
    const vehicleData = vehicleDataResponse?.data;

    useEffect(() => {
        fetchVehicleDetails();
    }, []);

    const fetchVehicleDetails = useCallback(() => {
        if (vehicleRegNumber) {
            getVehicleDetails({
                url: `${APIConfig.GET_VEHICLE_LIVE_DETAILS.URL(vehicleRegNumber)}`
            });
        }
    }, [vehicleRegNumber]);

    const onMapViewLocation = useCallback(() => {
        const {lat: latitude, lng: longitude} = vehicleDataResponse?.data['gps:location'] || {};
        window.open(`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`, '_blank');
    }, [vehicleDataResponse]);

    return (
        <div className="live-vehicle-details-screen">
            <div className="live-vehicle-details__header">
                <div className="live-vehicle-details__left">
                    <LinkComponent route={CommonService._routeConfig.LiveVehicleListRoute()}>
                        <div className="live-vehicle-details__navigate-back">
                            <ImageConfig.ArrowBackIcon/>
                        </div>
                    </LinkComponent>
                    <div className="live-vehicle-details__reg-num">
                        {vehicleRegNumber}
                    </div>
                    {
                        isVehicleDataLoaded && <>
                            <div className="live-vehicle-details__status">
                                <TagComponent label={vehicleData?.vehicle_status_info}/>
                            </div>
                            <div className="live-vehicle-details__view-location">
                                <LinkComponent onClick={onMapViewLocation}>
                                    <ImageConfig.MapPlaceIcon/>&nbsp;View Location
                                </LinkComponent>
                            </div>
                        </>
                    }
                </div>
                <div className="live-vehicle-details__right">
                    <div className="live-vehicle-details__last-updated-at">
                        {<LastUpdatedAtComponent timestamp={vehicleDataRequestedAt}/>}
                    </div>
                    <div className="live-vehicle-details__refresh"
                         onClick={fetchVehicleDetails}
                    >
                        <ImageConfig.RefreshIcon/>
                    </div>
                </div>
            </div>
            <div className="live-vehicle-details__body">
                {
                    isVehicleDataLoading && <div className="live-vehicle-details-loading">
                        <LoaderComponent/>
                    </div>
                }
                {
                    isVehicleDataLoadingFailed && <div className="live-vehicle-details-loading-failed">
                        <StatusCardComponent title={"Failed to load"}/>
                    </div>
                }
                {
                    isVehicleDataLoaded && <>
                        <div className="ts-row live-vehicle-stats-wrapper">
                            <div className="ts-col">
                                <DashboardStatWidgetComponent title="State Of Charge"
                                                              value={vehicleData['can:soc']}
                                                              units="%"
                                                              timestamp={vehicleDataRequestedAt}
                                />
                            </div>
                            <div className="ts-col">
                                <DashboardStatWidgetComponent title="Distance To Empty*"
                                                              value={vehicleData['can:dte'] ? parseFloat(vehicleData['can:dte'])?.toFixed(2) : 0.00}
                                                              units="Kms"
                                                              timestamp={vehicleDataRequestedAt}
                                />
                            </div>
                            <div className="ts-col">
                                <DashboardStatWidgetComponent title="Total Alerts"
                                                              value={0}
                                                              timestamp={vehicleDataRequestedAt}
                                />
                            </div>
                            <div className="ts-col">
                                <DashboardStatWidgetComponent title="Odometer"
                                                              value={vehicleData['can:odometer'] ? parseFloat(vehicleData['can:odometer'])?.toFixed(2) : 0.00}
                                                              units="Kms"
                                                              timestamp={vehicleDataRequestedAt}
                                />
                            </div>
                        </div>
                        <CardComponent title="Current Driver Details" shadow={true}>
                            <div className="ts-row">
                                <div className="ts-col">
                                    <DataLabelValueComponent label={"Driver Name"}>
                                        {vehicleData?.hoto?.sp_name || "-"}
                                    </DataLabelValueComponent>
                                </div>
                                <div className="ts-col">
                                    <DataLabelValueComponent label={"Lithium ID"}>
                                        {vehicleData?.hoto?.sp_lithium_id?.toUpperCase() || "-"}
                                    </DataLabelValueComponent>
                                </div>
                                <div className="ts-col">
                                    <DataLabelValueComponent label={"Phone Number"}>
                                        {vehicleData?.hoto?.sp_mobile || "-"}
                                    </DataLabelValueComponent>
                                </div>
                                <div className="ts-col">
                                    <DataLabelValueComponent label={"Total Duration"}>
                                        -
                                    </DataLabelValueComponent>
                                </div>
                                <div className="ts-col">
                                    <DataLabelValueComponent label={"Handover Date/Time"}>
                                        {vehicleData?.hoto?.created_at ? moment(vehicleData?.hoto?.created_at).format("D MMM YYYY | hh:mm A") || "-"  : "-"}
                                    </DataLabelValueComponent>
                                </div>
                            </div>
                            <div className="ts-row">
                                <div className="ts-col">
                                    <DataLabelValueComponent label={"Over-speed Alert"}>
                                        -
                                    </DataLabelValueComponent>
                                </div>
                                <div className="ts-col">
                                    <DataLabelValueComponent label={"SOS Alert"}>
                                        -
                                    </DataLabelValueComponent>
                                </div>
                                <div className="ts-col"/>
                                <div className="ts-col"/>
                                <div className="ts-col"/>
                            </div>
                        </CardComponent>
                        <CardComponent title="Vehicle Details" shadow={true}>
                            <div className="ts-row">
                                <div className="ts-col">
                                    <DataLabelValueComponent label={"City"}>
                                        {vehicleData?.city?.toUpperCase() || "-"}
                                    </DataLabelValueComponent>
                                </div>
                                <div className="ts-col">
                                    <DataLabelValueComponent label={"Client Site Name"}>
                                        {vehicleData?.site?.toUpperCase() || "-"}
                                    </DataLabelValueComponent>
                                </div>
                                <div className="ts-col">
                                    <DataLabelValueComponent label={"Allocated to Site Since"}>
                                        {vehicleData?.site_mapping_since ? moment(vehicleData?.site_mapping_since).format("D MMM YYYY | hh:mm A") || "-"  : "-"}
                                    </DataLabelValueComponent>
                                </div>
                                <div className="ts-col">
                                    <DataLabelValueComponent label={"Device Type"}>
                                        {vehicleData?.device_type?.toUpperCase() || "-"}
                                    </DataLabelValueComponent>
                                </div>
                                <div className="ts-col"/>
                            </div>
                        </CardComponent>
                    </>
                }
            </div>
        </div>
    );

};

export default LiveVehicleDetailsScreen;

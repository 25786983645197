import "./HandshakeComponent.scss";
import {useLocation, useNavigate} from "react-router-dom";
import {CommonService} from "../../services";
import {useEffect, useState} from "react";
import {ENV} from "../../../constants";
import PageLoaderComponent from "../page-loader/pageLoaderComponent";

interface HandshakeComponentProps {

}

const HandshakeComponent = (props: HandshakeComponentProps) => {

    const [isHandShakeInProgress, setIsHandShakeInProgress] = useState<boolean>(true);
    const navigate = useNavigate();
    // read query params using searchparams
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    console.log(token);

    useEffect(() => {
        if (token) {
            // check if token is valid
            CommonService._api.get(ENV.REACT_APP_API_AUTH_URL + '/checkLogin', {}, {
                Authorization: `Bearer ${token}`
            }).then((response) => {
                // navigate(CommonService._routeConfig.DashboardRoute());
                setInterval(() => {
                    navigate(CommonService._routeConfig.DashboardRoute());
                    setIsHandShakeInProgress(false);
                }, 5000);
            }).catch((error) => {
                navigate(CommonService._routeConfig.LoginRoute());
            }).finally(() => {
                setInterval(() => {
                    setIsHandShakeInProgress(false);
                }, 5000);
            });
        }
    }, [token, navigate]);

    return (
        <div className={'handshake-component'}>
            {
                isHandShakeInProgress && (
                    <PageLoaderComponent/>
                )
            }
        </div>
    );

};

export default HandshakeComponent;

const primary = "#2AB7A3";
const secondary = "#232323";
const info = "#1479FF";
const backgroundColor = "#FBFCFC";
const textLight = '#000';
const textDark = '#EDECEE';
const error = '#FA6187';
const success = '#E4F6EA';
const successContrastText = '#00534F';
const warn = '#d57b4b';

export {
    primary,
    secondary,
    info,
    backgroundColor,
    textLight,
    textDark,
    warn,
    success,
    error,
    successContrastText
}

import {
    COMING_SOON_ROUTE,
    DASHBOARD_ROUTE,
    LIVE_BASE_ROUTE,
    LIVE_VEHICLE_DETAILS_ROUTE,
    LIVE_VEHICLE_LIST_ROUTE,
    LOGIN_ROUTE,
    NOT_FOUND_ROUTE,
    TEST_ROUTE,
} from "../../constants/RoutesConfig";

const DefaultRoute = () => {
    return "/";
}

const DashboardRoute = () => {
    return DASHBOARD_ROUTE;
}

const LiveBaseRoute = () => {
    return LIVE_BASE_ROUTE;
}

const LiveVehicleListRoute = () => {
    return LIVE_VEHICLE_LIST_ROUTE;
}

const LiveVehicleDetailsRoute = (vehicleRegNumber: string) => {
    return LIVE_VEHICLE_DETAILS_ROUTE.replace(":vehicleRegNumber", vehicleRegNumber);
}

const LoginRoute = () => {
    return LOGIN_ROUTE;
}

const NotFoundRoute = () => {
    return NOT_FOUND_ROUTE;
}

const TestRoute = () => {
    return TEST_ROUTE;
}

const ComingSoonRoute = () => {
    return COMING_SOON_ROUTE;
}

const RouteConfigService = {
    DefaultRoute,
    NotFoundRoute,
    LoginRoute,
    TestRoute,
    ComingSoonRoute,
    DashboardRoute,
    LiveBaseRoute,
    LiveVehicleListRoute,
    LiveVehicleDetailsRoute
}

export default RouteConfigService;

import "./LiveVehicleListScreen.scss";
import {ITableColumn} from "../../../shared/models/table.model";
import {useCallback, useMemo, useState} from "react";
import CardComponent from "../../../shared/components/card/CardComponent";
import TableWrapperComponent from "../../../shared/components/table-wrapper/TableWrapperComponent";
import {APIConfig} from "../../../constants";
import {LocationRenderer} from "../../../shared/renderers/LocationRenderer";
import {VehicleStatusRenderer} from "../../../shared/renderers/VehicleStatusRenderer";
import LinkComponent from "../../../shared/components/link/LinkComponent";
import {CommonService} from "../../../shared/services";
import SearchComponent from "../../../shared/components/search/SearchComponent";

interface LiveVehicleListScreenProps {

}

const ModuleName = 'LiveVehicleListScreen';

const LiveVehicleListScreen = (props: LiveVehicleListScreenProps) => {

    const [filters, setFilters] = useState<any>({
        search: '',
    });

    const columns: ITableColumn[] = useMemo<ITableColumn[]>(() => [
        {
            key: 'sNo',
            title: 'S.No',
            width: 100,
            render: (_, index) => {
                return <>
                    {index + 1}
                </>
            }
        },
        {
            key: 'registrationNumber',
            title: 'Vehicle Number',
            width: 160,
            render: (row) => {
                const {vehicle_num = ''} = row || {};
                return <>
                    {vehicle_num?.toUpperCase()}
                </>
            }
        },
        {
            key: "vehicle_status",
            title: "Vehicle Status",
            width: 200,
            render: (row) => {
                const {vehicle_status_info = ''} = row || {};
                return <VehicleStatusRenderer status={vehicle_status_info}/>
            }
        },
        {
            key: "location",
            title: "Last Known Location",
            width: 240,
            render: (row) => {
                return <LocationRenderer value={row}/>
            }
        },
        {
            key: "soc",
            title: "State Of Charge",
            width: 160,
            render: (row) => {
                const soc = row['can:soc'];
                return <>
                    {soc || '-'}%
                </>
            }
        },
        {
            key: "dte",
            title: "Distance To Empty*",
            width: 157,
            render: (row) => {
                const dte = row['can:dte'];
                return <>
                    {dte || '-'} Kms
                </>
            }
        },
        {
            key: "actions",
            title: "Actions",
            render: (row) => {
                const {vehicle_num = ''} = row || {};
                return <>
                    <LinkComponent
                        route={CommonService._routeConfig.LiveVehicleDetailsRoute(vehicle_num)}
                    >
                        View Details
                    </LinkComponent>
                </>
            }
        }
    ], []);

    const handleSearch = useCallback((value: string) => {
        setFilters({
            ...filters,
            search: value
        });
    }, [])

    return (
        <div className="live-vehicle-list-screen list-screen">
            <CardComponent className="live-vehicle-list-card list-screen__card">
                <div className="list-screen__card-header">
                    <div className="list-screen__card-header__left">
                        <SearchComponent placeholder={"Search for vehicle number"}
                                         onChange={handleSearch}
                                         value={filters.search}
                        />
                    </div>
                    <div className="list-screen__card-header__right">

                    </div>
                </div>
                <div className="live-vehicle-list-table-container">
                    <TableWrapperComponent
                        columns={columns}
                        url={APIConfig.GET_ALL_VEHICLES_LIVE_STATUS.URL}
                        method={APIConfig.GET_ALL_VEHICLES_LIVE_STATUS.METHOD}
                        extraPayload={filters}
                        isPaginated={false}
                        moduleName={ModuleName}
                    />
                </div>
            </CardComponent>
        </div>
    );

};

export default LiveVehicleListScreen;

import React, {useCallback} from "react";
import {ImageConfig} from "../../constants";
import ToolTipComponent from "../components/tool-tip/ToolTipComponent";

interface LocationRendererProps {
    maxWidth?: number;
    value: any;
}

export const LocationRenderer = (props: LocationRendererProps) => {

    const {value} = props;

    const address = value['gps:address'];
    const location = value['gps:location'];
    const maxWidth = props.maxWidth || 200;
    const latitude = location?.lat;
    const longitude = location?.lng;

    const onMapClick = useCallback(() => {
        window.open(`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`, '_blank');
    }, [latitude, longitude]);

    return (
        <>
            {address ? <div className={'location-renderer'}>
                <ToolTipComponent tooltip={address}>
                    <div className={'location-address'} style={{maxWidth: maxWidth, overflow: "hidden", textOverflow: "ellipsis"}}>{address}</div>
                </ToolTipComponent>
                &nbsp;
                <ToolTipComponent tooltip={"View On map"}>
                    <div className={'cursor-pointer'} onClick={onMapClick}>
                        <ImageConfig.MapPlaceIcon className={'location-map-icon'}/>
                    </div>
                </ToolTipComponent>
            </div> : <ToolTipComponent tooltip={"Not available"}>
                <div className={'location-renderer'}>-</div>
            </ToolTipComponent>}
        </>
    );
}

import {call, put, takeEvery} from "redux-saga/effects";
import {CommonService} from "../../shared/services";
import {GET_ALL_VEHICLES_LIVE_DATA, setAllVehiclesLiveData} from "../actions/vehicle.action";

function* getAllVehiclesLiveData() {
    try {
        // @ts-ignore
        const resp = yield call(CommonService._vehicle.GetAllVehiclesLiveStatusAPICall);
        yield put(setAllVehiclesLiveData(resp?.data || []));
    } catch (error: any) {
        yield put(setAllVehiclesLiveData(undefined));
    }
}

export default function* vehicleSaga() {
    yield takeEvery(GET_ALL_VEHICLES_LIVE_DATA, getAllVehiclesLiveData);
}

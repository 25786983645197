import "./DashboardScreen.scss";

interface DashboardScreenProps {

}

const DashboardScreen = (props: DashboardScreenProps) => {

    return (
        <div className={'dashboard-container'}>
            Dashboard - Coming Soon
        </div>
    );
};

export default DashboardScreen;

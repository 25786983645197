import "./DesignSystemScreen.scss";
import * as Yup from "yup";
import React, {useCallback, useEffect, useState} from "react";
import {Field, FieldProps, Form, Formik, FormikHelpers} from 'formik';
import CardComponent from "../../shared/components/card/CardComponent";
import ButtonComponent from "../../shared/components/button/ButtonComponent";
import {ImageConfig} from "../../constants";
import FormDebuggerComponent from "../../shared/components/form-debugger/FormDebuggerComponent";
import _ from "lodash";
import FormikInputComponent from "../../shared/components/form-controls/input/formik-input/FormikInputComponent";

interface DesignSystemScreenProps {

}

const designSystemFormValidationSchema = Yup.object({
    name: Yup.string()
        .required("Name is required"),
    // email: Yup.string()
    //     .required("Email is required"),
    // phone: Yup.string()
    //     .required("Phone Number is required"),
    // otp: Yup.string()
    //     .required("OTP is required")
    //     .min(6, "OTP should be 6 digits")
    //     .max(6, "OTP should be 6 digits"),
    // dob: Yup.mixed()
    //     .required("Date Of Birth is required"),
    // isAdmin: Yup.mixed()
    //     .required("Is Admin is required"),
    // gender: Yup.string()
    //     .required('Gender is required'),
    // about: Yup.string()
    //     .required('About is required'),
    // isTnCAgreed: Yup.boolean()
    //     .required("Terms and Conditions acceptance agreement is required"),
});

const DesignSystemInitialValues = {
    name: "",
    // email: "",
    // phone: "",
    // otp: "",
    // dob: "",
    // role: "",
    // about: "",
    // gender: "",
    // isAdmin: true,
    // isTnCAgreed: false
};

const DesignSystemScreen = (props: DesignSystemScreenProps) => {

    const [designSystemFormInitialValues] = useState<any>(_.cloneDeep(DesignSystemInitialValues));

    const onSubmit = useCallback((values: any, {setSubmitting}: FormikHelpers<any>) => {
        console.log(values);
        setSubmitting(true);
        setTimeout(() => {
            setSubmitting(false);
        }, 500);
    }, []);

    return (
        <div className="design-system-screen screen">
            <div className="design-system-form-container">
                <CardComponent title={"Design System"}>
                    <Formik
                        validationSchema={designSystemFormValidationSchema}
                        initialValues={designSystemFormInitialValues}
                        validateOnChange={false}
                        validateOnBlur={true}
                        enableReinitialize={true}
                        validateOnMount={true}
                        onSubmit={onSubmit}
                    >
                        {({values, isSubmitting, resetForm, isValid, validateForm, errors}) => {
                            // eslint-disable-next-line react-hooks/rules-of-hooks
                            useEffect(() => {
                                validateForm();
                            }, [validateForm, values]);
                            return (
                                <Form className={"t-form"} noValidate={true}>
                                    <FormDebuggerComponent values={values} errors={errors} showDebugger={false}/>
                                    <Field name={'name'} className="t-form-control">
                                        {
                                            (field: FieldProps) => (
                                                <FormikInputComponent
                                                    label={'Name'}
                                                    placeholder={'Enter Name'}
                                                    type={"text"}
                                                    required={true}
                                                    formikField={field}
                                                    fullWidth={true}
                                                />
                                            )
                                        }
                                    </Field>
                                    <Field name={'email'} className="t-form-control">
                                        {
                                            (field: FieldProps) => (
                                                <FormikInputComponent
                                                    label={'Email'}
                                                    placeholder={'Ex: john@doe.com'}
                                                    type={"email"}
                                                    // required={true}
                                                    formikField={field}
                                                    fullWidth={true}
                                                />
                                            )
                                        }
                                    </Field>
                                    <div className="t-form-actions">
                                        {/*<ButtonComponent type={"button"}*/}
                                        {/*                 fullWidth={true}*/}
                                        {/*                 variant={"outlined"}*/}
                                        {/*                 onClick={() => {*/}
                                        {/*                     resetForm();*/}
                                        {/*                 }}*/}
                                        {/*>*/}
                                        {/*    Clear*/}
                                        {/*</ButtonComponent>&nbsp;*/}
                                        <ButtonComponent type={"submit"}
                                                         fullWidth={true}
                                                         loading={isSubmitting}
                                            disabled={!isValid}
                                        >
                                            Submit
                                        </ButtonComponent>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </CardComponent>
            </div>
        </div>
    );
};


export default DesignSystemScreen;
